import { graphql } from "gatsby";
import CarTableForCars from './carTableForCars';

export default CarTableForCars;

export const query = graphql`
  query CarsBySize($skip: Int!, $limit: Int!, $carType: String!, $size: String) {
    allCarData(
      sort: { fields: CoalescedProbDeath }
      filter: {
        CarTypeTeoalida: { eq: $carType }
        VehicleSizeCategory: { eq: $size }
      }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        CarTypeTeoalida
        FormalModelName
        FormalMakeName
        ModelYear
        CoalescedProbDeath
        CoalescedProbInjury
        ProbDeathNOTEquippedWithAEB
        ProbDeathIfEquippedWithAEB
        VehicleSizeCategory
        CoalescedRscRating
      }
    }

    groupings: allCarData(
      filter: { CarTypeTeoalida: { eq: $carType }, VehicleSizeCategory: { eq: $size } }
    ) {
      group(field: CoalescedProbDeath) {
        fieldValue
        totalCount
      }
    }
  }
`;


// import React from "react";
// import { Link, graphql } from "gatsby";

// import { SharedLayout } from "../layouts/SharedLayout";
// import SEO from "components/seo";
// import { CarTable } from "components/CarTable";
// import Pagination from "@material-ui/lab/Pagination";
// import PaginationItem from "@material-ui/lab/PaginationItem";

// const CarTablePageBySize = ({ data, pageContext }) => {
  // const cars = data.allCarData.nodes;
  // const {
    // displayCarType,
    // carType,
    // numPages,
    // currentPage,
    // skip,
    // size,
    // sizeOptions,
    // yearOptions,
  // } = pageContext;

  // const handleSizeChange = (event) => {
    // window.location = `/${displayCarType}s/${event.target.value}`;
  // };

  // const handleYearChange = (event) => {
    // window.location = `/${displayCarType}s/${event.target.value}`;
  // };
  // return (
    // <SharedLayout pageContext={pageContext}>
      // <SEO title={`${size[0].toUpperCase() + size.slice(1)} ${displayCarType[0].toUpperCase() + displayCarType.slice(1)}s`} />
      // <br />
      // <br />
      // <br />
      // <h1>
        // {size[0].toUpperCase() + size.slice(1)} {carType}s
      // </h1>
      // <div>
        // Filter by:{" "}
        // <select onChange={handleSizeChange}>
          // <option value="all">all sizes</option>
          // {sizeOptions.map((o) => (
            // <option selected={size === o} value={o}>{o}</option>
          // ))}
        // </select>{" "}or{" "}
        // <select onChange={handleYearChange}>
          // <option value="all">all years</option>
          // {yearOptions.map((y) => (
            // <option value={y}>{y}</option>
          // ))}
        // </select>
      // </div>
      // <CarTable cars={cars} startIndex={skip} />
      // <Pagination
        // page={currentPage}
        // count={numPages}
        // renderItem={(item) => (
          // <PaginationItem
            // component={Link}
            // to={`/${displayCarType}s/${size}/${
              // item.page === 1 ? "" : item.page
            // }`}
            // {...item}
          // />
        // )}
      // />
    // </SharedLayout>
  // );
// };


// export default CarTablePageBySize;
